<template>
  <BCard
    header-tag="h2"
    header-bg-variant="ams-gray"
    header-border-variant="ams-header-orange"
    header-text-variant="black"
    header-class="border-bottom highlight-border"
    align="left"
    class="shadow mt-3"
  >
    <template slot="header">
      Mathematical Citation Quotient
    </template>
    <BRow>
      <BCol>
        <BTabs
          style="width:100%;"
          fill
        >
          <BTab
            title="MCQ Graph"
            lazy
          >
            <template slot="title">
              <span title="MCQ Graph">
                <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
                  Graph&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="chart-bar"
                  aria-hidden="true"
                />
              </span>
            </template>
            <Graph
              :data1="mcqGraph"
            />
          </BTab>
          <BTab
            v-if="showMCQ"
            title="MCQ Table"
          >
            <template slot="title">
              <span title="MCQ Table">
                <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
                  Table&nbsp;
                </span>
                <FontAwesomeIcon
                  icon="columns"
                  role="presentation"
                  aria-hidden="true"
                />
              </span>
            </template>
            <h3 class="table-title">
              MCQ for {{ abbr }}
            </h3>
            <Msntable :rows="mcqTable" />
          </BTab>
          <BTab
            title="All MCQ Table"
          >
            <template slot="title">
              <span title="All MCQ Table">
                All
                <span class="hide-xxs">
                  &nbsp;MCQ
                </span>
                <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
                  &nbsp;Table
                </span>&nbsp;
                <FontAwesomeIcon
                  role="presentation"
                  icon="columns"
                  aria-hidden="true"
                />
              </span>
            </template>
            <h3 class="table-title">
              The Median MCQ for all {{ serialType == 'series' ? 'Series' : 'Journal' }}s in MathSciNet
            </h3>
            <Msntable :rows="mcqAllTable" />
          </BTab>
          <BTab
            title="MCQ Description"
          >
            <template slot="title">
              <span title="Description">
                <span class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
                  Description&nbsp;
                </span>
                <FontAwesomeIcon
                  role="presentation"
                  icon="info-circle"
                  aria-hidden="true"
                />
              </span>
            </template>
            <div class="indent-xs">
              <br>
              <!-- eslint-disable-next-line -->
              <p>The <b>Mathematical Citation Quotient (MCQ)</b> for a given year is defined as the number of times the items published in the {{ serialType }} in the previous five years were cited by items in <a href="/mathscinet/mrcit/journal_list.html" title="View All Reference List Journals and Series">Reference List Journals and Series</a> published in the given year, divided by the number of articles the {{ serialType }} published in that same five-year period. </p>
              <p>The <b>All {{ serialType == 'series' ? 'Series' : 'Journal' }} Median MCQ</b> is the median of every {{ serialType }}'s MCQ. It provides a benchmark by which to understand the MCQ of a {{ serialType }}. (Note that we don’t compute MCQs before the year 2000.) </p>
              <p>The Median MCQ for All {{ serialType == 'series' ? 'Series' : 'Journal' }} Publications in {{ latestYear }} is {{ mcqAllValue }}</p>
              <br>
            </div>
          </BTab>
        </BTabs>
      </BCol>
    </BRow>
  </BCard>
</template>
<script>
// import Vue from 'vue'
import { formatNumber, titleCase } from '@/utils'
import { JournalAPI, SeriesAPI } from '@/js-api'

export default {
  name: 'ProfileMCQComponent',
  components: {
    Graph: () => import(/* webpackChunkName: "journal_graph" */ '@/components/journal/JournalGraph.vue'),
    Msntable: () => import(/* webpackChunkName: "journal_msntable" */ '@/components/journal/MsnTable.vue'),
  },
  // title: 'ProfileMCQ',
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    seriesId: {
      type: Number,
      required: true,
    },
    abbr: {
      type: String,
      required: true,
    },
    isRefList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mcq: [],
      mcqAll: [],
      mcqValue: -1,
      mcqAllValue: -1,
      // showMCQ: false,
    }
  },
  computed: {
    serialType: function() {
      if (this.isSeries) {
        return 'series'
      } else {
        return 'journal'
      }
    },
    isSeries: function() {
      return (this.seriesId && this.seriesId > -1)
    },
    latestYear: function() {
      const currentDate = new Date()
      currentDate.setMonth(currentDate.getMonth() - 18)
      return currentDate.getFullYear()
    },
    mcqTable: function() {
      let rows = []
      for (const i in this.mcq) {
        if (this.mcq[i].citingYear > this.latestYear) {
          break
        }
        rows.push([this.mcq[i].citingYear, this.mcq[i].mcq.toFixed(2), formatNumber(this.mcq[i].totalCitations), formatNumber(this.mcq[i].totalPublications)])
      }
      rows = rows.sort((x, y) => y[0] - x[0])
      rows.splice(0, 0, ['Year', 'MCQ', '<abbr title="Citations">Cit.</abbr>(5yr)', '<abbr title="Publications">Pub.</abbr>(5yr)'])
      return rows
    },
    mcqAllTable: function() {
      let rows = []
      for (const i in this.mcqAll) {
        if (this.mcqAll[i].citingYear > this.latestYear) {
          break
        }
        rows.push([this.mcqAll[i].citingYear, this.mcqAll[i].mcq.toFixed(2), formatNumber(this.mcqAll[i].totalCitations), formatNumber(this.mcqAll[i].totalPublications)])
      }
      rows = rows.sort((x, y) => y[0] - x[0])
      rows.splice(0, 0, ['Year', 'MCQ', '<abbr title="Citations">Cit.</abbr>(5yr)', '<abbr title="Publications">Pub.</abbr>(5yr)'])
      return rows
    },
    showMCQ: function() {
      for (const i in this.mcq) {
        if (this.mcq[i].mcq > 0) {
          return true
        }
      }
      return false
    },
    mcqGraph: function() {
      const format = {
        changeable: {
          title: 'MCQ',
          x: [],
          y: [
            [],
            [],
          ],
          axis: {
            x: 'Year',
            y: 'MCQ',
          },
          label: [
            this.isSeries ? 'Series MCQ' : 'Journal MCQ',
            'All ' + titleCase(this.serialType) + ' Median MCQ',
          ],
        },
        id: 'mcq-graph',
        type: 'bar',
      }

      for (const i in this.mcq) {
        if (this.mcq[i].citingYear > this.latestYear) {
          break
        }
        // if (!this.showMCQ && this.mcq[i].mcq > 0){
        //   this.showMCQ = true
        // }
        format.changeable.y[0].push(this.mcq[i].mcq.toFixed(2))
      }
      if (!this.showMCQ) {
        // Done this way instead of with mcqValue to avoid possible race condition
        format.changeable.y[0] = []
      }

      for (const i in this.mcqAll) {
        if (this.mcqAll[i].citingYear > this.latestYear) {
          break
        }
        format.changeable.x.push(this.mcqAll[i].citingYear)
        format.changeable.y[1].push(this.mcqAll[i].mcq.toFixed(2))
      }

      return format
    },
  },
  mounted: function() {
    this.getMCQ()
  },
  methods: {
    getMCQ: async function() {
      let result = {}
      if (this.isSeries) {
        result = await SeriesAPI.mcq(this.seriesId)
      } else {
        result = await JournalAPI.mcq(this.groupId)
      }

      if (result.details) {
        if (result.details.length === 0) {
          console.log('Could not get MCQ')
        } else {
          let mcq = []
          if (this.isSeries) {
            mcq = result.details.results[0].mcq
          } else {
            mcq = result.details[0].mcq
          }

          if (mcq.length > 0) {
            this.mcq = mcq[0].yearMap
          }
        }
      }

      if (result.mcqAll) {
        this.mcqAll = result.mcqAll
      }
      if (result.mcqAllSeries) {
        this.mcqAll = result.mcqAllSeries
      }

      for (const i in this.mcqAll) {
        if (this.mcqAll[i].citingYear === this.latestYear) {
          if (this.mcq.length > 0) {
            this.mcqValue = this.mcq[i].mcq
          }
          this.mcqAllValue = this.mcqAll[i].mcq
        }
      }

      // this.renderMCQ = true
      this.sendToParent()
    },
    sendToParent: function() {
      const dataToReturn = {
        mcqValue: this.mcqValue,
        mcqAllValue: this.mcqAllValue,
      }
      // To use, you need the parent's <SerialMCQ> to have @return="functionToHandleReturn"
      this.$emit('return', dataToReturn)
    },
  },
}
</script>
